import React from "react";

import "@shopify/polaris/build/esm/styles.css";
import {
  Checkbox,
  Heading,
  Select,
  LegacyStack as Stack,
} from "@shopify/polaris";

function StyleSection({ inputsHandler, inputField }) {
  return (
    <>
      <Heading>Style</Heading>
      <Stack vertical>
        <Select
          id="style"
          label="Framing"
          onChange={inputsHandler}
          options={[
            { label: "Canvas", value: "canvas" },
            { label: "Dibond", value: "dibond" },
            { label: "Floating Canvas", value: "floatingCanvas" },
            { label: "Floating Dibond", value: "floatingDibond" },
            { label: "Frame", value: "framed" },
            { label: "Box Frame", value: "boxFrame" },
            { label: "Print", value: "print" },
          ]}
          value={inputField.style}
        />
        {(inputField.style === "framed" ||
          inputField.style === "boxFrame" ||
          inputField.style === "floatingCanvas" ||
          inputField.style === "floatingDibond") && (
          <>
            <Select
              id="frameMaterial"
              label="Frame Material"
              onChange={inputsHandler}
              options={[
                { label: "Natural Grain", value: "natural" },
                { label: "Lighter Grain", value: "lightestWoodGrain" },
                { label: "Gray Grain", value: "grayWoodGrain" },
                { label: "Walnut Grain", value: "walnutWoodGrain" },
                { label: "Dark Grain", value: "blackWoodGrain" },
                { label: "White", value: "white" },
                { label: "Black", value: "black" },
                { label: "Gold", value: "gold" },
                { label: "Silver", value: "silver" },
                { label: "White (metallic)", value: "whiteMetallic" },
                { label: "Black (metallic)", value: "blackMetallic" },
                { label: "Red (metallic)", value: "redMetallic" },
                { label: "Blue (metallic)", value: "blueMetallic" },
              ]}
              value={inputField.frameMaterial}
            />
          </>
        )}
        {(inputField.style === "canvas" || inputField.style === "dibond") && (
          <>
            <Select
              id="edgeFinish"
              label="Edge Color"
              onChange={inputsHandler}
              options={[
                { label: "White", value: "white" },
                { label: "Black", value: "black" },
              ]}
              value={inputField.edgeFinish}
            />
            {/* <br />
            <TextStyle variation="subdued">
              Note: Edge Color is not visible on all scenes
            </TextStyle> */}
          </>
        )}
        <Stack vertical={false} distribution="fill">
          <Checkbox
            id="isBordered"
            label="Border"
            onChange={inputsHandler}
            checked={inputField.isBordered}
          />
          {inputField.style === "framed" && (
            <Checkbox
              id="frameMounted"
              label="Mountboard"
              onChange={inputsHandler}
              checked={inputField.frameMounted}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default StyleSection;
